<template>
	<el-container class="dh-container">

		<el-aside class="el-aside" v-if="this.$store.state.dh.collapseState.isCollapse">
			<menuSide/>
		</el-aside>
		<el-aside class="el-aside-show" v-if="!this.$store.state.dh.collapseState.isCollapse">
			<menuSide/>
		</el-aside>

		<el-container>
			<el-header class="top-header">
				<menuHeader/>
			</el-header>
			
			<el-main>
				<keep-alive>
					<router-view  v-if="$route.meta.cache == true"></router-view>
				</keep-alive>
				<router-view v-if="!$route.meta.cache"></router-view>
			</el-main>

		</el-container>


	</el-container>
</template>

<script>
	import menuSide from './components/aside'
	import menuHeader from './components/header'

	export default {
		name: 'dh-layout-header-aside',
		mainHeight:0,
		components: {
			menuSide,
			menuHeader
		},
		mounted() {
		},
		created () {
			//加载一次用户信息，防止被替代
			this.$store.dispatch('dh/user/load')
		}
	}
</script>

<style lang="scss">
.dh-container{
	height: 100%;
	.top-header{
		padding:0;
		height:4.5rem !important;
	}
	.el-aside{
		height:100vh;
		background-color: #35364C;
		overflow-y: scroll;
		width:2.8rem !important;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.el-aside-show{
		height:100vh;
		background-color: #35364C;
		overflow-y: scroll;
		width:11rem !important;
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.el-main{
		padding:0rem 1.5rem 1.5rem 1.5rem;
		height:80vh;
		background-color: #F6F7FB;
	}
}
</style>