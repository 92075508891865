
// 控制layout 菜单缩放
export default {
	namespaced: true,
	state: {
		isCollapse: true
	},
	mutations: {
		isCollapseTure (state) {
		  	state.isCollapse = true
		},
		isCollapseFalse (state) {
		  	state.isCollapse = false
		}
	}
}