import store from '@/store'

/**
 * 页面权限控制
 * 例如：this.hasPermission('code')
 */
export default {
	
  	install (Vue, options) {
		Vue.prototype.hasPermission = function (permission,permissionList = null){
			if(permissionList == null){
				permissionList = store.getters['dh/menu/getPermission'];
			}
			console.log("注册或获取全局指令 hasPermission permission :",permission)
			if(permissionList && permissionList.length && permissionList.includes(permission)){
				return true;
			}else{
				return false;
			}
		}
/**
* 注册或获取全局指令
*/
	Vue.directive('hasPermission', {
		inserted (el, binding, vnode, oldVnode) {
			let permissionArray = store.getters['dh/menu/getPermission'];

			if(permissionArray.length > 0){	
	            if(!Vue.prototype.hasPermission(binding.value,permissionArray)){
	                el.parentNode.removeChild(el);
	            }
			}
		}
	})
  }
}


// ok l - dh/menu/getPermission