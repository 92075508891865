import cookies from '@/utils/cookies/index.js'

export default {
  namespaced: true,
  state: {
    // 用户信息
    info: {}
  },
  actions: {
    /**
     * @description 设置用户数据
     * @param {Object} state vuex state
     * @param {*} info info
     */
    set ({ state, dispatch }, info) {
      return new Promise(async resolve => {        
        // store 赋值
        state.info = info
        cookies.set('uid', info.id, {expires:null});
        cookies.set('token', info.token, {expires:null});
        // 持久化
        await dispatch('dh/db/set', {
          key:"userInfo",
          value:info
        }, { root: true })
        // end
        resolve()
      })
    },
    /**
     * @description 从数据库取用户数据
     * @param {Object} state vuex state
     */
    load ({ state, dispatch }) {
      return new Promise(async resolve => {
        // store 赋值
        state.info = await dispatch('dh/db/get', {
          key:'userInfo'
        }, { root: true })
        // end
        resolve()
      })
    }
  },
  getters: {
    /**
    * 获取当前用户登陆信息
    */
    getUserInfo(state){
      return state.info;
    }
  }
}
