import cookies from '@/utils/cookies/index'

export default {
	namespaced: true,
	actions: {

		set (context, {
			key = '',
			value = '',
		}) {
			var uid = cookies.get("uid");  	
			if(typeof(value) == 'string'){
				localStorage.setItem( key + uid, value);
			}else{
				localStorage.setItem( key + uid,JSON.stringify(value));
			}
		},

		get (context,{
			key = ''
		}) {			
			return new Promise(resolve => {
				var uid = cookies.get("uid");  
				var value = localStorage.getItem(key + uid);
				resolve(JSON.parse(value));
			})
		}
	}
}