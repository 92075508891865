// 设置文件
import { getMenu,getPermissionByAdminId } from '@/api/getUcenterData'
import setting from '@/utils/config/index.js'


export default {
  namespaced: true,
  state: {
    // 顶栏菜单
    header: [],
    // 侧栏菜单
    aside: [],
    // 按钮权限
    permission: []
  },
  actions: {
    /**
    * 初始化菜单
    */
    initMenus ({state, dispatch }) {
      return new Promise(async (resolve, reject) => {
        var menusList = [];
        var permissionList = [];
        //读取本地缓存
        // menusList = await dispatch('getMenuByStorage');
        // permissionList = await dispatch('getPermissionByStorage');
        // 如果菜单为空
        if(menusList && menusList.length == 0){
          // 开始请求菜单接口
          menusList = await dispatch('getMenuByServer');
          //保存
          dispatch('setMenuByStorage',menusList);
          //获取权限
          permissionList = await dispatch('getPermissionByServer');
          //保持权限在前端
          dispatch('setPermissionByStorage',permissionList);
        }

        state.permission = permissionList;
        state.aside = menusList;
        state.header = [];
        resolve(menusList);
      })
    },


    /**
    * 通过本地存储获取当前用户的菜单
    */
    // getMenuByStorage ({ state, dispatch }){
    //   return new Promise(async (resolve, reject) => {
    //     var menusList = [];
    //     // 先从本地缓存获取菜单
    //     menusList = await dispatch('dh/db/get', {
    //       menList:'menuList'
    //     }, { root: true })
    //     resolve(menusList)
    //   })
    // },


    /**
    * 通过本地存储设置当前用户的菜单
    */
    setMenuByStorage ({ state, dispatch },arr){
      return new Promise(async (resolve, reject) => {
        // 先从本地缓存获取菜单
        await dispatch('dh/db/set', {
          key:"menuList",
          value:arr
        }, { root: true })        
        resolve()
      })
    },


    /**
    * 通过服务器获取当前用户的菜单
    * 用户必须要登陆
    */
    getMenuByServer ({ state, dispatch }){
      return new Promise(async (resolve, reject) => {
        getMenu().then(async resData => {

          console.log('resData: ', resData)
          if(resData.resultCode==1){
              var menusList = resData.data;
              //保存菜单
              resolve(menusList)
          }else{
              reject();
          }
        }).catch(err => {
          reject(err)
        })
      })
    },


    /**
    * 从服务器上获取用户权限    
    */
    getPermissionByServer ({state, dispatch}){
      return new Promise(async (resolve, reject) => {
        getPermissionByAdminId().then(async resData =>{
          var permissionList = [];  
          if(resData.resultCode==1){
              var res = [];
              res = resData.data;

              if(res && res!=null && res.length>0){
                console.log("resolve",res)
                for(var i = 0 ; i < res.length; i++){
                  if(res[i].code!=null){
                    permissionList.push(res[i].code);
                  }
                }
              }    
          }else{
            this.$message({message: resData.resultMsg,type: 'error'});
          }
         resolve(permissionList);
        }).catch(err => {
          console.log('err: ', err)
          reject(err)
        })
      })
    },


    /**
    * 从本地存储上获取用户权限    
    */
    // getPermissionByStorage ({state, dispatch}){
    //   return new Promise(async (resolve, reject) => {
    //     var permissionList = [];
    //     // 先从本地缓存获取菜单
    //     permissionList = await dispatch('dh/db/get', {
    //       menList:'permissionList'
    //     }, { root: true })
    //     resolve(permissionList)
    //   })
    // },


    /**
    * 保存权限至本地
    */
    setPermissionByStorage ({ state, dispatch },arr){
      return new Promise(async (resolve, reject) => {
        // 先从本地缓存获取权限
        await dispatch('dh/db/set', {
          key:"permissionList",
          value:arr
        }, { root: true })        
        resolve()
      })
    },


    /**
    * 设置权限
    */
    // setPermission ({ state, dispatch},arr){
    //   return new Promise(resolve => {
    //     state.permission = arr;  
    //     resolve();
    //   })
    // },


  },
  getters: {
    /**
    * 获取当前用户菜单信息
    */
    getMenuInfo(state){
      return state.aside;
    },
    /**
    * 获取用户权限
    */
    getPermission(state){
      return state.permission;
    }
  },
  mutations: {
    /**
     * @description 设置顶栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    headerSet (state, menu) {
      // store 赋值
      state.header = menu
    },
    /**
     * @description 设置侧边栏菜单
     * @param {Object} state vuex state
     * @param {Array} menu menu setting
     */
    asideSet (state, menu) {
      // store 赋值
      state.aside = menu
    }
  }
}
// ok l