// 引入 Element
import ElementUI from 'element-ui'

// 引入 Element css
import 'element-ui/lib/theme-chalk/index.css'

//权限管理
import permission from '@/plugin/permission'

export default {
  async install (Vue) {
    Vue.prototype.$env = process.env.NODE_ENV
    // 设置为 false 以阻止 vue 在启动时生成生产提示
    // https://cn.vuejs.org/v2/api/#productionTip
    Vue.config.productionTip = false
    // 当前环境
    Vue.use(ElementUI)
    Vue.use(permission)
  }
}


// OK