import axios from 'axios'
import { Message } from 'element-ui'
import setting from '@/utils/config/index.js'
//cookies
import cookies from '@/utils/cookies/index'

// 创建一个 axios 实例
const service = axios.create({
  withCredentials:true,
  baseURL: setting.url.UcenterRootUrl,
  timeout: 40000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在请求发送之前做一些处理
    // 让每个请求携带token-- ['I-Token']为自定义key 请根据实际情况自行修改
    config.headers['I-Token'] = cookies.get('token')
    return config
  },
  error => {
    // 发送失败
    console.log(error)
    Promise.reject(error)
  }
  )

// 响应拦截器
service.interceptors.response.use(
  response => {
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    if(dataAxios.resultCode && dataAxios.resultCode == -1000){
      cookies.remove('uid');
      cookies.remove('token');
      // 显示提示
      Message({
        message: dataAxios.resultMsg,
        type: 'error',
        duration: 5 * 10000
      })

      window.location.href = setting.url.LoginUrl;
      return Promise.reject(dataAxios.resultMsg); 
    }else{
      return dataAxios
    }
  },
  error => {
    if (error && error.response) {
      switch (error.response.status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
    }
    return Promise.reject(error)
  }
)

export default service