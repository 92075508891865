export default {
	
	//地址映射
	url:{
		ossPath: window.location.host.indexOf("127.0.0.1") == -1 ? process.env.VUE_APP_OSS_PATH : process.env.VUE_APP_OSS_PATH, 
		BaseRootUrl:window.location.host.indexOf("127.0.0.1") == -1 ? process.env.VUE_APP_BASE_PATH : process.env.VUE_APP_BASE_PATH1,
		UcenterRootUrl: window.location.host.indexOf("127.0.0.1") == -1 ? process.env.VUE_APP_UCENTER_PATH : process.env.VUE_APP_UCENTER_PATH1,
		LoginUrl:window.location.host.indexOf("127.0.0.1") == -1 ? process.env.VUE_APP_LOGIN_PATH : process.env.VUE_APP_LOGIN_PATH1,
	
	},

	// 其他映射
	info:{
		LoginText:process.env.VUE_APP_TITLE,
	}
}