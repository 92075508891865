import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate' //首先引入


const files = require.context('./modules', false, /\.js$/)
const mods = {}

files.keys().forEach(key => {
  console.log(mods,"========== Vuex ==========",key)
  mods[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})


console.log(mods,"========== Vuex ==========" )
Vue.use(Vuex)

const dh = {
	namespaced: true,
	modules:mods
}

export default new Vuex.Store({
  plugins: [persistedState()], //Vuex刷新state数据消失怎么办？使用状态持久化来解决 
  modules: {
    dh 
  }
})
