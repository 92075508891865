import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '../../store'
 
import corePlugin from '@/plugin/core'


//图片插件、点击图片弹出、对图片大小进行缩放
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer);
Viewer.setDefaults({
  Options: { 
    "inline": true, "button": true, "navbar": true, 
    "title": true, "toolbar": true, "tooltip": true, 
    "movable": true, "zoomable": true, "rotatable": true, 
    "scalable": true, "transition": true, "fullscreen": true, 
    "keyboard": true, "url": "data-source" }
});

// 核心插件Element和权限控制
Vue.use(corePlugin)
Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// ok 