import Vue from 'vue'
import VueRouter from 'vue-router'
import setting from '@/utils/config/index.js'
// 进度条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import cookies from '@/utils/cookies/index'
import store from '@/store'
// 路由数据
import routes from './routerUrl'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base:'/base/',
  routes:routes
})


//标记是否已经拉取权限信息
let isFetchPermissionInfo = false
/**
 * 路由拦截
 * 权限验证
 */
router.beforeEach(async (to, from, next) => {
  // 进度条
  NProgress.start()
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some(r => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    const token = cookies.get('token')
    console.log(token,"这里暂时将cookie里是否存有token作为验证是否登录的条件")
    if (token && token !== 'undefined') {
      if (!isFetchPermissionInfo || store.state.dh.menu.aside.length == 0) {
        await fetchPermissionInfo();
        isFetchPermissionInfo = true;
        next(to.fullPath, true)
      } else {
        next()
      }
    } else {
      // // 没有登录的时候跳转到登录界面
      // // 携带上登陆成功之后需要跳转的页面完整路径
      // window.location.href = setting.url.LoginUrl;
      // // https://github.com/d2-projects/d2-admin/issues/138
      // NProgress.done()
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
      
    }
  } else {
    // 不需要身份校验 直接通过
    next()
  }
})

/**
* 路由结束
*/
router.afterEach(to => {
  // 进度条
  NProgress.done()
})



/**
* 解析后台带出来的菜单权限
*/
let fetchPermissionInfo = async () => {
  var permission = await store.dispatch('dh/menu/initMenus');
  //处理动态添加路由
  await Promise.resolve()
}

//解决连续两次点击相同菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
