<template>
  <div class="header-index">
  	<div class="header">

	  	<div style="padding: 0 0 0 15px;">
			<span  @click="toggleClick">		    
				<svg
					:class="{'is-active':this.$store.state.dh.collapseState.isCollapse}"
					class="hamburger"
					viewBox="0 0 1024 1024"
					xmlns="http://www.w3.org/2000/svg"
					width="70"
					height="70"
				>
				<path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM142.4 642.1L298.7 519a8.84 8.84 0 0 0 0-13.9L142.4 381.9c-5.8-4.6-14.4-.5-14.4 6.9v246.3a8.9 8.9 0 0 0 14.4 7z" />
				</svg>
			</span>
			
			<span class="title">
				德航工单管理系统
			</span>
		</div>

		
 
	  	<div class="user">

			<img src="../../assets/img/user.gif" v-if="this.user.avatar == '' || this.user.avatar==null">

			<img :src="this.user.avatar" class="avatar" v-else>
			  <!-- {{this.user.avatar}} -->
			  <!-- <img :src="this.user.avatar" class="avatar"> -->
			<el-dropdown @command="handleCommand">
				<span class="el-dropdown-link">
				{{this.user.name?this.user.name:this.user.user_name}}<i class="el-icon-caret-bottom"></i>
				</span>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item command="modifyPassword">修改密码</el-dropdown-item>
					<el-dropdown-item command="modifyAdmin">个人资料</el-dropdown-item>
					<el-dropdown-item divided command="signout">退出登录</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
	  	</div>

  	</div>
  	<el-dialog title="密码修改" :visible.sync="modifyVisible" width="30%" :modal-append-to-body="false" class="admin-header">
  		<el-form :model="modify" status-icon :rules="rules" ref="modifyPassForm">
  			<el-form-item  prop="oldPass">
  				<el-input type="password" placeholder="请输入密码" v-model="modify.oldPass" autocomplete="off">
					<template slot="prepend">
					<span >原密码</span>
					</template>
          </el-input>
  			</el-form-item>
  			<el-form-item  prop="pass">
  				<el-input type="password" placeholder="请输入密码" v-model="modify.pass" autocomplete="off">
            <template slot="prepend">
               <span >新密码</span>
            </template>
          </el-input>
  			</el-form-item>
  			<el-form-item prop="checkPass">
  				<el-input type="password" placeholder="请输入确认密码" v-model="modify.checkPass" autocomplete="off">
            <template slot="prepend">
               <span >确认密码</span>
            </template>
          </el-input>
  			</el-form-item>
  			<el-form-item>
  				<div style="float: right;">
  					<el-button type="primary" size="small" @click="submitModifyPass">提 交</el-button>
            <el-button size="small" @click="modifyVisible = false">取 消</el-button>
  				</div>
  			</el-form-item>
  		</el-form>
  	</el-dialog>

  	<el-dialog  title="个人资料" :visible.sync="adminVisible" width="30%" :modal-append-to-body="false" class="admin-header">
  		<el-form ref="modifyAdminForm" :model="admin" :rules="modifyAdminForm" v-loading="formEditLoading" class="demo-formData" center>
  			<el-form-item prop="name">
  				<el-input type="text"   v-model="admin.name" placeholder="请输入用户姓名">
					<template slot="prepend">
						<div ><span style="color: red">*</span><span style="margin-left: 0.5rem;">用户姓名</span></div>
					</template>    
          		</el-input>
				
			</el-form-item>

  			<el-form-item prop="mobile">  
  				<el-input  type="text"  v-model="admin.mobile" placeholder="请输入手机号码">
					<template slot="prepend">
					<span >手机号码</span>
					</template>     
          		</el-input>
  			</el-form-item>
  			<el-form-item  prop="email">  
  				<el-input  type="text"  v-model="admin.email" placeholder="请输入邮箱">
            <template slot="prepend">
              <span >邮箱</span>
            </template>        
          </el-input>
  			</el-form-item>
  			<el-form-item  prop="position">
  				<el-input type="text"   v-model="admin.position" placeholder="请输入职称">
					<template slot="prepend">
					<span >职称</span>
					</template>    
          		</el-input>
  			</el-form-item>

  			<el-form-item label="头像：">
				<!-- <el-image
                    style="width: 100px; height: 100px"
                    :src="this.imgList[0]"
                    fit="fill"
                    :preview-src-list="imgList">
                </el-image> -->
				<el-upload
				 	:limit="1"
					:action="uploadUrl"
					:data="uploadDate"
					list-type="picture-card"
					:on-success="handleImageSuccess"
					:on-preview="handlePreview"
					:file-list="imgList"
					:on-remove="handleRemove">
					<!-- <i class="el-icon-plus" v-if="imgList.length == 0"></i> -->
					</el-upload>
				<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
				</el-dialog>
			</el-form-item>


  		</el-form>
  		<div slot="footer" class="modify-footer">
  			<el-button type="primary" size="small" @click="submitModifyAdmin">确 定</el-button>
        <el-button size="small" @click="adminVisible = false">取 消</el-button>
  		</div>
  	</el-dialog>
  </div>
</template>

<script>
  import cookies from '@/utils/cookies/index'
 	import setting from '@/utils/config/index.js'

  import {signout,updateAdminPassword,findByAdminNameInfo,updateAdminInfo} from '@/api/getUcenterData'
  export default {
  	data(){
  		return {

			// 文件上传的地址
			uploadUrl:setting.url.ossPath+'uploadFile',
			// uploadUrl:"http://114.55.126.232:7011/oss/uploadFile",
			// 文件上传头部
			uploadHeader:{
				content_type:'application/x-www-form-urlencoded'
			},
        	// 文件上传参数
			uploadDate:{
				bucketName:"dh-order",
				fileName:"dh-order",
			},
 
  			websock: null,
	        stopInterval: "",
	        alreadTry: false,
	        userName:'',
	        modifyVisible:false,
	        adminVisible:false,
	        formEditLoading:false,
	        warningCount:'',
	        modify:{
	          oldPass:"",
	          pass:"",
	          checkPass:"",
	        },
	        admin:{
	          name:"",
	          mobile:"",
	          email:"",
			  position:"",
			  avatar:"",
	        },
	        rules: {
	          oldPass: [
	            { required: true, message: '请输入原先密码', trigger: 'blur' }
	          ],
	          pass: [
	            { validator: this.validatePass, trigger: 'blur' },
              { min: 8,max: 25,message: '长度在 8 到 25个字符'}, 
              {pattern:/(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,25}/, message: '只能输入8-25个,且必须有数字字母特殊字符组合'}
	          ],
	          checkPass: [
	            { validator: this.validatePass2, trigger: 'blur' }
	          ]
	        },
	        modifyAdminForm:{
	              name: [{required: true, message: '请输入用户姓名', trigger: 'blur'}],
	              email :[
	                  {
	                       pattern:/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
	                       message:'请输入正确邮箱地址'
	                  }],
	            mobile: [
	                 { required: false, message: '请输入联系方式' },
	                    {pattern: /^((0[0-9]{2,3}\-)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$)|(^((\(\d{3}\))|(\d{3}\-))?(1[23456789]\d{9}))$/, message: '请输入正确联系方式'}
	                ],
	          },
			  
			// 图片存储路径 
			imageUrl: '',
			// 图片上传后的列表
			imgList:[
				{name: 'food.jpg', url: 'http://dh-order.oss-cn-hangzhou.aliyuncs.com/files_test/imgs/2020/09/28/dh-order-202009281616051ed068.jpg'}
				],
			dialogImageUrl:"",

			dialogImageUrl: '',
        	dialogVisible: false
		}
	  },
 
	components:{
	},
 	mounted() {
         
    },
    created (){
      let uid = cookies.get("uid");
	  this.user = JSON.parse(localStorage.getItem("userInfo" + uid));
	  
	  this.dialogImageUrl = this.user.avatar;
	          console.log("  this.dialogImageUrl " ,  this.dialogImageUrl );
    },
  	methods:{

      handleImageSuccess(res, file, imgList) {
          // 上传成功
          // this.dialogImageUrl = imgList;
          var url = 'http://'+res.data.url+''
          console.log("上传成功" + imgList.length);
		  
		  this.dialogImageUrl = url;
        //   this.form.imgList.push(url)
      },
	// 上传失败
    uploadError(res){
        console.log("上传失败" + res);
	},
	// 
    handlePreview(file) {
	   var url = 'http://'+res.data.url+''
          console.log("上传成功" + imgList.length);
		  
		  this.dialogImageUrl = url;
	},
	// 删除图片逻辑
    handleRemove(file, imgList) {
        console.log(file, imgList);
    },
    beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 JPG 格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isJPG && isLt2M;
    },

		 toggleClick() {
			 if(this.$store.state.dh.collapseState.isCollapse){
			    this.$store.commit('dh/collapseState/isCollapseFalse');
				this.$store.state.dh.collapseState.isCollapse = false;
			 }else{
			    this.$store.state.dh.collapseState.isCollapse = true;
				this.$store.commit('dh/collapseState/isCollapseTure');
			 }
			 console.log("是否激活状态：", this.$store.state.dh.collapseState.isCollapse);
		 },

  		handleCommand:function(command){
        	if(command == "signout"){
                   this.$confirm('此操作将退出登录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
                  }).then(() => {
                 		this.signout(true);
                  }).catch(() => {
                              
                  });
          		
      		}else if(command == "modifyPassword"){
      			this.modifyPassword();
      		}else{
      			this.modifyAdmin();
      		}
      	},
       signout:function(showMsg = true){
           //退出
	        signout().then((res)=>{
	          if(showMsg){            
	            this.$message({type: 'success',message: '退出成功'});
	          }
				cookies.remove('uid');
				cookies.remove('token')
				const token = cookies.get('token')
	          	window.location.href = window.location.protocol + '//' + window.location.host + '/base/login';
	        })
      	},
      	validatePass:function(rule, value, callback){
      		if (value === '') {
      			callback(new Error('请输入新密码'));
      		} else {
      			if (this.modify.checkPass !== '') {
      				this.$refs.modifyPassForm.validateField('checkPass');
      			}
      			callback();
      		}
      	},
      	validatePass2:function(rule, value, callback){
      		if (value === '') {
      			callback(new Error('请再次输入密码'));
      		} else if (value !== this.modify.pass) {
      			callback(new Error('两次输入密码不一致!'));
      		} else {
      			callback();
      		}
      	},
      	//修改个人资料
      	modifyAdmin:function(){
      		this.findByAdminNameInfo();
      		this.adminVisible = true;
      	},
      //显示信息
      findByAdminNameInfo:function(){
      	let param ={
      		user_name:this.user.user_name
      	}
      	findByAdminNameInfo(param).then((resData)=>{
			  if(resData.resultCode==1){
				   var res = resData.data
					this.admin = res;
			}else{
				this.$message({message: resData.resultMsg,type: 'error'});
			}
			//   修改个人资料后、要把缓存里面的用户信息更新！！！
      	})
      },
      //修改个人资料
      submitModifyAdmin:function(){
      	this.$refs.modifyAdminForm.validate((valid) => {
      		if(valid){
      			this.formEditLoading = true;
      			let param = {
      				user_name:this.user.user_name,
      				name:this.admin.name,
      				mobile:this.admin.mobile,
      				email:this.admin.email,
					position:this.admin.position,
					avatar:this.dialogImageUrl
      			}
      			updateAdminInfo(param).then((res)=>{
					  this.formEditLoading = false;
					  this.user.avatar= this.dialogImageUrl
					  this.$store.dispatch('dh/user/set',this.user);

      				if(res.resultCode == 1){
      					this.$message({message: '修改成功',type: 'success'});
      					this.adminVisible = false;
						location.reload();
      				}else{
      					this.$message({message: res.resultMsg ,type: 'error'});
      				}
      			})
      		}
      	})
      },
      modifyPassword:function(){
        //修改密码
        this.modifyVisible = true;
        this.$nextTick(()=>{
        	this.$refs["modifyPassForm"].resetFields();
        })
    },
    submitModifyPass:function(){
        //提交修改密码
        this.$refs["modifyPassForm"].validate((valid) => {
        	if(valid){
        		let param = {
        			user_name:this.user.user_name,
        			password:this.modify.pass,
        			oldPass:this.modify.oldPass
        		}
        		updateAdminPassword(param).then((res)=>{
        			if(res.resultCode == 1){
        				this.$message({message: '修改成功，请重新登陆',type: 'success'});
        				this.signout(false);
        			}else{
        				this.$message({message: res.resultMsg ,type: 'error'});
        			}
        		})
        	}
        })
    }
}
}
</script>

<style lang="scss">
.header-index{

	.hamburger {
		display: inline-block;
		vertical-align: middle;
		width: 22px;
		height: 22px;
	}

	.hamburger.is-active {
		transform: rotate(180deg);
	}


	.header{
		height:4.5rem;
		background-color: #3C92FF;
		display:flex;
		justify-content: space-between;
		padding: 0 1.5rem 0 0;
		line-height:4.5rem;
		.title{
			color:#fff;
			font-size: 1.1rem;
			font-weight:500;
			letter-spacing:1px;
			display: inline-block;
			vertical-align: middle;
			margin-left:10px;
		}
		.user{
			img{
				width: 1.2rem;
				height:1.2rem;
				border-radius: 50%;
				display: inline-block;
				vertical-align: middle;
			}
			.el-dropdown{
				font-size: 0.8rem;
				color:#fff;
				font-weight: 400;
				margin-left:0.5rem;
				height:3rem;				
				.el-icon-caret-bottom{
					margin-left:1rem;
				}
			}
		}
	}
  .admin-header{
    .el-input-group__prepend {
        width: 30%;
        color: #21293B;
    }
    .el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-input-group__append, .el-input-group__prepend {
        background-color: #E9ECEE;
    }
    .el-form-item__error {
        left: 30%;
    }
  }
  .el-dialog__headerbtn {
      top: 1.25rem;
  }
  .el-dialog__title{
      font-weight: bold;
  }

	// 下面都是上传图片组件的样式
    .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
  	}
  	.avatar-uploader .el-upload:hover {
    	border-color: #409EFF;
  	}
  	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 50px;
		height: 50px;
		line-height: 50px;
		text-align: center;
  	}
	.avatar {
		width: 50px;
		height: 50px;
		display: block;
	}
  
}
</style>