<template>
	<div class="aside">
		<el-menu 
				:collapse="this.$store.state.dh.collapseState.isCollapse" 
				:default-active="defaultActive" 
				class="el-menu-vertical-demo" 
				background-color="#35364C"  
				:unique-opened="true"
				text-color="#969AA6" 
				active-text-color="#fff" 
				@select="selectMenu" @open="openMenu" 
				ref="asideMenu" >

			<aside-menu :menuList="this.$store.state.dh.menu.aside"></aside-menu>

    	</el-menu>
	</div>
</template>

<script>
import AsideMenu from './asideMenu'
	export default {
		name:'dh-menu-side',
		data () {
			return{
				preIndex:window.location.pathname,
			}
		},
		components:{
			'aside-menu' : AsideMenu
		},
		mounted(){
				console.log("defaultActive",this.$store.state.dh.collapseState.isCollapse)
		},
		created(){
		},
		computed:{
			defaultActive:function(){
				let path = this.$router.history.current.meta.defaultActive;
				if(path && path != ''){
					return path;
				}else{
					return window.location.pathname;
				}
			}
		},
		methods:{
			
			selectMenu:function(index,indexPath){
				 if(indexPath.indexOf(this.preIndex) < 0){
				 	this.$refs.asideMenu.close(this.preIndex)
				 }
				 let pathList = index.split('/');
				 let prePathList = window.location.pathname.split('/');
				 // 如果在相同前缀系统中，使用路由跳转
				 if(pathList[1] == prePathList[1]){
				 	let path = '/';
				 	for(let i = 2 ; i < pathList.length; i++){
				 		
				 		if(i != pathList.length - 1){
				 			path += pathList[i] + '/';
				 		}else{
				 			path += pathList[i];
				 		}
				 	}

				 	this.$router.push({ path: path});
				 }else{

				 	window.location.href = window.location.protocol + '//' + window.location.host + index;

				 }


			},
			openMenu:function(index,indexPath){
				this.preIndex = index;
			}
		}
	}
</script>

<style lang="scss">

	.aside{

	  .el-menu-vertical-demo:not(.el-menu--collapse) {
			width: 200px;
			min-height: 400px;
	   }


		// margin-top:6rem;
		overflow-x: hidden;
		.el-menu{
			border-right: none;
			.el-menu-item{
				span{
					margin-left:1rem;
				}
			}
			.el-menu-item:focus, .el-menu-item:hover{
			    outline: 0;
			    color:#fff !important;
			    background-color: #35364C !important;
			    .aside-img{
			    	&:first-child{
						display: none !important;
			    	}
			    	display: inline-block !important;
			    }
			}
			.is-active>{
				.el-submenu__title{
					&:first-child{
					  	outline: 0;
					    color:#fff !important;
					    background-color: #35364C !important;
			    	}
			    	.aside-img{
				    	&:first-child{
							display: none !important;
				    	}
				    	display: inline-block !important;
			    }
				}
			}
			.el-submenu__title:focus, .el-submenu__title:hover {
			    outline: 0;
			    color: #fff !important;
			    background-color: #35364C !important;
			    .aside-img{
			    	&:first-child{
						display: none !important;
			    	}
			    	display: inline-block !important;
			    }

			}
			.el-submenu__title{
				span{
					margin-left:1rem;
				}
			}
		}
		
	}
</style>