import request from '@/plugin/axios/ucenter'
// 用户登陆
export function login(data) {
  return request({
    url: '/login/login',
    method: 'POST',    
    data    
  });
};
export function signout(data) {
  return request({
    url: '/login/logout',
    method: 'GET',    
    params:data    
  });
};
//用户列表
export function findAdminPage(data) {
  return request({
    url: '/user/findAdminPage',
    method: 'get',    
    params:data    
  });
};
//获取所下的userName
export function getLawersByDeptId(data) {
  return request({
    url: '/user/getLawersByDeptId',
    method: 'get',    
    params:data    
  });
};

//获取部门userName
export function findByDeptIdRoleAdminName(data) {
  return request({
    url: '/user/findByDeptIdRoleAdminName',
    method: 'get',    
    params:data    
  });
};


//查找部门下的角色成员
export function getAdminByDeptIdAndRoleNamenPage(data) {
  return request({
    url: '/user/findAdminByDeptIdAndRoleName',
    method: 'get',    
    params:data    
  });
};

//用户新增或编辑
export function updateOrAddAdmin(data) {
  return request({
    url: '/user/updateOrAddAdmin',
    method: 'POST',    
    data    
  });
};
//用户信息删除
export function deleteAdminId(data) {
  return request({
    url: '/user/deleteAdminId',
    method: 'POST',    
    params:data   
  });
};
//菜单列表信息
export function getMenuList(data) {
  return request({
    url: '/menu/getMenuList',
    method: 'get',    
    params:data   
  });
};
//菜单列表信息
export function getMenu(data) {
  return request({
    url: '/menu/getMenu',
    method: 'get',    
    params:data   
  });
};

//菜单列表信息
export function getTreeMenus(data) {
  return request({
    url: '/menu/getTreeMenus',
    method: 'get',    
    params:data   
  });
};
//菜单列表信息
export function getMenusByPId(data) {
  return request({
    url: '/menu/getMenusByPId',
    method: 'get',    
    params:data   
  });
};
//菜单新增或编辑
export function addOrUpdateMenu(data) {
  return request({
    url: '/menu/addOrUpdateMenu',
    method: 'POST',    
    data    
  });
};
//菜单信息删除
export function deleteMenuId(data) {
  return request({
    url: '/menu/deleteMenuId',
    method: 'POST',    
    params:data   
  });
};
//部门列表信息
export function getDepartmentList(data) {
  return request({
    url: '/department/getDepartmentList',
    method: 'get',    
    params:data   
  });
};
//部门下分所列表信息
export function getSubofficeDepartmentList(data) {
  return request({
    url: '/department/getSubofficeDepartmentList',
    method: 'get',    
    params:data   
  });
};

//部门列表信息
export function getDeptInfoList(data) {
  return request({
    url: '/department/getDeptInfoList',
    method: 'get',    
    params:data   
  });
};
//获取科室列表信息
export function getDeptByTypeAndUseSpecial(data) {
  return request({
    url: '/department/getDeptByTypeAndUseSpecial',
    method: 'get',    
    params:data   
  });
};

//获取所有的和下的所
export function getAllDistrAndDepartMent(data) {
  return request({
    url: '/department/getAllDistrAndDepartMent',
    method: 'get',    
    params:data   
  });
};

//部门新增或编辑
export function updateOrAddDepartment(data) {
  return request({
    url: '/department/updateOrAddDepartment',
    method: 'POST',    
    data    
  });
};
//菜单信息删除
export function deleteDepartmentId(data) {
  return request({
    url: '/department/deleteDepartmentId',
    method: 'POST',    
    params:data   
  });
}
  //获取全部角色
  export function getAllRole(data) {
    return request({
        url: '/role/getAllRole',
        method: 'get',
        params: data
    })
}
  //新增编辑角色信息
  export function addOrUpdateRole(data) {
    return request({
        url: '/role/addOrUpdateRole',
        method: 'POST',
         data
    })
}
  //删除角色信息
  export function deleteRoleById(data) {
    return request({
        url: '/role/deleteRoleById',
        method: 'POST',
         data
    })
}
  //根据角色名称查找用户信息
  export function findDataByName(data) {
    return request({
        url: '/role/findDataByName',
        method: 'get',
        params: data
    })
}

export function findRoleDataById(data) {
  return request({
      url: '/role/findRoleDataById',
      method: 'get',
      params: data
  })
}

  //根据用户Id查找用户信息
  export function getAdminById(data) {
    return request({
        url: '/user/findAdminById',
        method: 'get',
        params: data
    })
}

  //获取所有权限
  export function getAllPermission(data) {
    return request({
        url: '/Permission/getAllPermission',
        method: 'get',
        params: data
    })
}
//新增编辑权限信息
export function addOrUpdatePermission(data) {
  return request({
      url: '/Permission/addOrUpdatePermission',
      method: 'POST',
       data
  })
}
//删除权限信息
export function deletePermissionById(data) {
  return request({
      url: '/Permission/deletePermissionById',
      method: 'POST',
      data
  })
}
//id获取部门信息
export function getDepartmentById(data) {
    return request({
        url: '/department/getDepartmentById',
        method: 'get',
        params: data
    })
}
//id获取用户所属权限
export function getAdminRoleByAdminId(data) {
    return request({
        url: '/user/getAdminRoleByAdminId',
        method: 'get',
        params: data
    })
}
//修改密码
export function updateAdminPassword(data) {
    return request({
        url: '/user/updateAdminPassword',
        method: 'POST',
        params:data
    })
}
//根据用户名查询
export function findByAdminNameInfo(data) {
    return request({
        url: '/user/findByAdminNameInfo',
        method: 'get',
        params: data
    });
}
//修改个人资料
export function updateAdminInfo(data) {
    return request({
        url: '/user/updateAdminInfo',
        method: 'get',
        params: data
    });
}
//获取全部菜单
export function getAllMenus(data) {
    return request({
        url: '/menu/getMenuList',
        method: 'get',
        params: data
    });
}
export function getAdminListByDeptId(data) {
  return request({
      url: '/user/getAdminListByDeptId',
      method: 'get',
      params: data
  });
}

 


//获取权限列表
export function getPermissionList(data) {
  return request({
      url: '/Permission/getPermissionList',
      method: 'get',
      params: data
  })
}
  //获取角色对应的菜单
  export function getRoleMenu(data) {
      return request({
          url: '/roleMenu/getRoleMenu',
          method: 'get',
          params: data
      })
  }
  
    //获取角色对应的权限
  export function getRolePermission(data) {
      return request({
          url: '/roleMenu/getRolePermission',
          method: 'get',
          params: data
      })
  }
  //获取消息页面
  export function getMessagePage(data) {
    return request({
        url: '/message/getMessagePage',
        method: 'get',
        params: data
    })
}
  //新增编辑消息
  export function addOrUpdateMessage(data) {
    return request({
        url: '/message/addOrUpdateMessage',
        method: 'POST',
        data
    })
}
//新增消息
export function saveMessage(data) {
    return request({
        url: '/message/saveMessage',
        method: 'POST',
        data
    })
}
  //删除消息
  export function deleteMessageById(data) {
    return request({
        url: '/message/deleteMessageById',
        method: 'POST',
        data
    })
}
//根据id消息
  export function getMessageById(data) {
    return request({
        url: '/message/getMessageById',
        method: 'get',
        params: data
    })
}

//根据code获取字典表LIST
export function getDictionaryListByCode(data) {
    return request({
        url: '/sysDictionary/getDictionaryListByCode',
        method: 'get',
        params: data
    });
}
 
//获取权限
export function getPermissionById(data) {
    return request({
        url: '/Permission/getPermissionById',
        method: 'get',
        params:data
    });
}
//获取当前用户权限
export function getPermissionByAdminId(data) {
    return request({
        url: '/roleMenu/getPermissionByAdminId',
        method: 'get',
        params:data
    });
}
//证件列表信息
export function finAdminCertificateList(data) {
    return request({
        url: '/adminCertificate/finAdminCertificateList',
        method: 'get',
        params:data
    });
}
//证件新增
export function updateOrAddAdminCertificate(data) {
  return request({
    url: '/adminCertificate/updateOrAddAdminCertificate',
    method: 'POST',    
    data   
  });
};
 

//获取三亚市地区和各所
export function getDepartmentNameInfo(data) {
  return request({
    url: '/department/getDepartmentNameInfo',
    method: 'get',
    params: data  
  });
};
//通知消息
export function getMessageAllPage(data) {
  return request({
    url: '/message/getMessageAllPage',
    method: 'get',
    params: data  
  });
};
 

 

 
//获取所有的和部门
export function getDepartmentAllList(data) {
  return request({
     url: '/department/getDepartmentAllList',
    method: 'GET',    
    params:data    
  });
};
//获取部门列表
export function getAppLevelDept(data) {
    return request({
        url: '/ucenterApi/getAppLevelDept',
        method: 'GET',
        params:data
    });
};
//获取用户权限
export function getPermissionLisBigData(data) {
    return request({
        url: '/ucenterApi/getPermissionList',
        method: 'GET',
        params:data
    });
};
//删除消息信息
export function deleteMessageRecById(data) {
  return request({
    url: '/message/deleteMessageRecById',
    method: 'POST',    
    params:data   
  });
};
//未读消息信息更新
export function updateMessageRecById(data) {
  return request({
    url: '/message/updateMessageRecById',
    method: 'POST',    
    params:data   
  });
};
//用户锁定状态
export function updateLockFlag(data) {
  return request({
    url: '/user/updateLockFlag',
    method: 'POST',    
    data     
  });
};
 
//其他单位用户编辑或新增
export function updateOrAddOtherUser(data) {
  return request({
    url: '/otherUser/updateOrAddOtherUser',
    method: 'POST',    
    data     
  });
};
 
//获取所管辖的所有IDS
export function getAllDeptIds(data) {
    return request({
        url: '/department/getAllDeptIds',
        method: 'GET',
        params:data
    })
}

//获取组织(树形结构)
export function getOrganizationInfoList(data) {
    return request({
        url: '/openApi/getOrganizationInfoList',
        method: 'POST',
        params:data
    })
}


//添加或编辑组织
export function addOrEditOrganization(data) {
  return request({
      url: '/organization/addOrEditOrganization',
      method: 'POST',
      params:data
  })
}

//条件查询项目
export function getOrgProjectPage(data) {
  return request({
      url: '/orgProject/getOrgProjectPage',
      method: 'POST',
      params:data
  })
}

// 添加或编辑项目
export function addAndEditProject(data) {
  return request({
      url: '/orgProject/addAndEditProject',
      method: 'POST',
      params:data
  })
}

// 删除项目
export function delProject(data) {
  return request({
      url: '/orgProject/delProject',
      method: 'POST',
      params:data
  })
}