import layoutHeaderAside from '@/layout'

// vue 路由加载（生产环境和开发环境），只有生产环境使用懒加载
const _import = require('../libs/' + process.env.NODE_ENV)



/**
 * 在主框架内显示
 */
const frameIn = [
   {
    path: '/',
    redirect: { name: 'index' },
    component: layoutHeaderAside,
    children: [ 
      {
        path: 'role/list',
        name: 'roleList',
        meta: {
          auth: true,
          breadcrumb:[
            {
              name:'系统管理',
              path:'/role/list',
            },
            {
              name:'角色管理',
              path:'/role/list',
            },
          ]
  
        },
       component: _import('role/list')
     },
      {
      path: 'permission/list',
      name: 'PermissionList',
      meta: {
          auth: true,
          defaultActive:'/ucenter/permission/list',
          breadcrumb:[
            {
              name:'系统管理',
              path:'',
            },
            {
              name:'权限管理',
              path:'/permission/list',
            },
          ]
  
        },
       component: _import('permission/list')
     },
     
 
      {
      path: 'user/index',
      name: 'user',
      meta: {
          auth: true,
          breadcrumb:[
            {
              name:'用户管理',
              path:'/user/index',
            },
          ]
  
        },
       component: _import('user/index')
     },
     {
        path: 'department/index',
        name: 'department',
        meta: {
          auth: true,
          breadcrumb:[
            {
              name:'部门管理',
              path:'/department/index',
            },
          ]
        },
        component: _import('department/index')
      },

        {
            path: 'organization/index',
            name: '组织管理',
            meta: {
                auth: true,
                breadcrumb:[
                    {
                        name:'组织管理',
                        path:'/organization/index',
                    },
                ]
            },
            component: _import('organization/index')
        },

        {
          path: 'orgProject/index',
          name: '项目管理',
          meta: {
              auth: true,
              breadcrumb:[
                {
                    name:'项目管理',
                    path:'/orgProject/index',
                },
            ]
              
          },
          component: _import('orgProject/index')
      },


      {
        path: 'sysDictionary/index',
        name: '字典管理',
        meta: {
            auth: true,
            breadcrumb:[
              {
                  name:'字典管理',
                  path:'/sysDictionary/index',
              },
          ]
            
        },
        component: _import('sysDictionary/index')
      },

        {
        path: 'menu/index',
        name: 'menu',
        meta: {
          auth: true,
          breadcrumb:[
            {
              name:'菜单管理',
              path:'/menu/index',
            },
          ]
        },
        component: _import('menu/index')
      }, 

      // 用户中心
       {
        path: 'home/index',
        name: 'index',
        meta: {
          auth: true,
          breadcrumb:[
            {
              name:'首页',
              path:'/home/index',
            }
          ]
        },
        component: _import('home/index')
      },
      {
        path: 'test/index',
        name: 'testIndex',
        meta: {
          auth: true,
          breadcrumb:[
            {
              name:'测试',
              path:'/test/index',
            }
          ]
        },
        component: _import('test/index')
      },
        {
            path: 'workOrder/index',
            name: 'workOrder',
            meta: {
                auth: true,
                breadcrumb:[
                    {
                        name:'工单管理',
                        path:'/workOrder/index',
                    }
                ]
            },
            component: _import('workOrder/index')
        },
        {
            path: 'workOrder/workOrderInfo',
            name: 'workOrderInfo',
            meta: {
                auth: true,
                breadcrumb:[
                  {
                    name:'工单管理',
                    path:'/workOrder/index',
                },
                    {
                        name:'工单详情',
                        path:'/workOrder/workOrderInfo',
                    }
                ]
            },
            component: _import('workOrder/workOrderInfo')
        },

        {
            path: 'workOrder/test',
            name: 'test2',
            meta: {
                auth: true,
                breadcrumb:[
                  {
                    name:'工单管理',
                    path:'/workOrder/test',
                },
                    {
                        name:'工单详情',
                        path:'/workOrder/test',
                    }
                ]
            },
            component: _import('workOrder/test')
        },

        {
            path: 'workOrder/myManageWorkOrder',
            name: 'workOrderInfo',
            meta: {
                auth: true,
                breadcrumb:[
                    {
                        name:'转发工单',
                        path:'/workOrder/myManageWorkOrder',
                    }
                ]
            },
            component: _import('workOrder/myManageWorkOrder')
        },
        
        {
            path: 'workOrder/luckyDraw',
            name: 'luckyDraw',
            meta: {
                auth: true,
                breadcrumb:[
                    {
                        name:'抽奖',
                        path:'/workOrder/luckyDraw',
                    }
                ]
            },
            component: _import('workOrder/luckyDraw')
        },


        {
          path: 'workOrder/workTime',
          name: 'workTime',
          meta: {
              auth: true,
              breadcrumb:[
                  {
                      name:'工时汇总报表',
                      path:'/workOrder/workTime',
                  }
              ]
          },
          component: _import('workOrder/workTime')
      },

  ]
  }
]


/**
 * 在主框架之外显示
 */
 const frameOut = [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: _import('system/login')
  }
]
 const passOut = [
  // 修改密码
  {
    path: '/password',
    name: 'password',
    component: _import('system/pass')
  }
]

export default [
...frameIn,
...frameOut
]
