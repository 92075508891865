<template>
	<div>
		<template v-for="(item,index) in menuList">
			<!-- 判断有子菜单，并且是一级菜单 -->
			<el-submenu :index="''+item.url+''"  v-if="item.children && item.children.length > 0 && item.icon && item.icon != ''">
				<template slot="title">
					<i :class="item.icon" :style="{width:'18px',height:'18px',display:'inline-block'}" class="aside-img"/>
					<i :class="item.icon" :style="{width:'18px',height:'18px',display:'none'}" class="aside-img"/>
					<span slot="title">{{item.name}}</span>
				</template>
				<aside-menu :menuList="item.children"></aside-menu>
			</el-submenu>
			<!-- 判断是一级菜单 -->
			<el-menu-item :index="''+item.url+''"  v-else-if="item.children.length <= 0 && item.icon && item.icon != ''">
					<i :class="item.icon" :style="{width:'18px',height:'18px',display:'inline-block'}" class="aside-img"/>
					<i :class="item.icon" :style="{width:'18px',height:'18px',display:'none'}" class="aside-img"/>
				<span slot="title">{{item.name}}</span>
			</el-menu-item>
			<!-- 判断二级及以下菜单 -->
			<el-submenu :index="''+item.url+''"  v-else-if="item.children && item.children.length > 0">
				<template slot="title">
					<span slot="title">{{item.name}}</span>
				</template>
				<aside-menu :menuList="item.children"></aside-menu>
			</el-submenu>
			<el-menu-item :index="''+item.url+''"  v-else>
				<span slot="title">{{item.name}}</span>
			</el-menu-item>
    	</template>
	</div>
</template>

<script>
	export default{
		name:'aside-menu',
		data(){
			return {

			}
		},
		props:['menuList'],
		methods:{

		}
	}
</script>